import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject } from 'rxjs';

const DEFAULT_FONTSIZE = 16;
const DEFAULT_THEME = 'light';

const FONT_SIZE_KEY = 'SELECTED_FONZSIZE';
const LNG_KEY = 'SELECTED_LANGUAGE';
const THEME_KEY = "SELECTED_THEME";

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  selectedFontsize = new BehaviorSubject<number>(DEFAULT_FONTSIZE);
  selectedLanguage = '';
  selectedTheme = new BehaviorSubject<string>(DEFAULT_THEME);

  constructor(private translate: TranslateService, private storage: Storage) {
    // Automatische Aktualisierung sobald Variable geändert wird
    this.selectedFontsize.subscribe(x => {
      document.documentElement.style.fontSize = x + 'px';
    });
    this.selectedTheme.subscribe(x => {
      document.body.setAttribute('color-theme', x);
    })

    // Startwerte setzen
    this.storage.get(FONT_SIZE_KEY).then((val) => {
      this.selectedFontsize.next(val ?? DEFAULT_FONTSIZE);
    });
  }

  updateFontsize(size) {
    this.selectedFontsize.next(size);

    this.storage.set(FONT_SIZE_KEY, size);
  }

  setInitialAppLanguage() {
    let language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);

    this.storage.get(LNG_KEY).then((val) => {
      if (val) {
        this.setLanguage(val);
        this.selectedLanguage = val;
      }
    });
  } //End setInitialAppLanguage()

  setLanguage(lng) {
    this.translate.use(lng);
    this.selectedLanguage = lng;
    this.storage.set(LNG_KEY, lng);
  } //End setLanguage

  getLanguage() {
    return this.selectedLanguage;
  }

  updateTheme(theme) {
    this.selectedTheme.next(theme);

    this.storage.set(THEME_KEY, theme);
  }

  applyThemeFromStorage() {
    this.storage.get(THEME_KEY).then((val) => {
      this.selectedTheme.next(val);
    })
  }
}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BikemainguardGuard } from './tab1/tourismus/bike-main/bikemainguard.guard';

const routes: Routes =[
  //Standard Pfad (Wir bei der Initialiserung der App aufgerufen)
  {
  path:'',
  redirectTo: 'loading',  //Verweis auf den Pfad zur Loading-Seite
  pathMatch: 'full'
  },
    
  //Pfad zur Loading-Seite
  {
    path: 'loading',
    loadChildren: () => import('./loading/loading.module').then(m => m.LoadingPageModule)
  },

  //Pfad zur Sprachauswahl
  {
    path: 'sprache',
    loadChildren: () => import('./sprache/sprache.module').then( m => m.SprachePageModule)
  },
  {
    //Pfad zur Tourismus
    path: 'tourismus',
    children:[
      
      
      {
      path: '', 
      loadChildren: () => import('./tab1/tourismus/tourismus.module').then( m => m.TourismusPageModule)
      },
      {
      path: 'goldeck',
      loadChildren: () => import('./tab1/tourismus/goldeck/goldeck.module').then( m => m.GoldeckPageModule)
      },
      {
      path: 'drautalperle',
      loadChildren: () => import('./tab1/tourismus/drautalperle/drautalperle.module').then( m => m.DrautalperlePageModule)
      },
      {
        path: 'millstaettersee',
        children:[
          {
            path: '',
            loadChildren: () => import('./tab1/tourismus/millstaettersee/millstaettersee.module').then( m => m.MillstaetterseePageModule)
          },
          {
            path: ':strandbadId',
            loadChildren: () => import('./tab1/tourismus/millstaettersee/millstaettersee-detail/millstaettersee-detail.module').then( m => m.MillstaetterseeDetailPageModule)
          },
     
      ]
      },

      {
        //Pfad zu Sehenswürdigkeiten
        path: 'sehenswuerdigkeiten',
      children:[
        {
        path: '',
        loadChildren: () => import('./tab1/tourismus/sehenswuerdigkeiten/sehenswuerdigkeiten.module').then( m => m.SehenswuerdigkeitenPageModule)
      },
      {
        path: ':sehenswuerdigkeitId',
        loadChildren: () => import('./tab1/tourismus/sehenswuerdigkeiten/sehenswuerdigkeit-detail/sehenswuerdigkeit-detail.module').then( m => m.SehenswuerdigkeitDetailPageModule)
      },
    ]},
    {
      path:'bike-main',
      children:[
        {
          //Pfad zur Übersicht
          path: '',
          loadChildren: () => import('./tab1/tourismus/bike-main/bike-main.module').then( m => m.BikeMainPageModule)
        },
        {
          path: 'radwegespittal',
          loadChildren: () => import('./tab1/tourismus/bike-main/radwegespittal/radwegespittal.module').then( m => m.RadwegespittalPageModule)
        },
        
        
        {
          path: 'drauweg',
          loadChildren: () => import('./tab1/tourismus/bike-main/drauweg/drauweg.module').then( m => m.DrauwegPageModule)
        },
        {
          path: 'millstaetter-see-radweg',
          loadChildren: () => import('./tab1/tourismus/bike-main/millstaetter-see-radweg/millstaetter-see-radweg.module').then( m => m.MillstaetterSeeRadwegPageModule)
        },
        {
          path:'verleih',
          children:
          [
            {
              path: '',
              loadChildren: () => import('./tab1/tourismus/bike-main/verleih/verleih.module').then( m => m.VerleihPageModule)
            },
            {
              path: ':bikestoreId',
              loadChildren: () => import('./tab1/tourismus/bike-main/verleih/bikestore-detail/bikestore-detail.module').then( m => m.BikestoreDetailPageModule)
            },
          ]
        },/*
        },
        {
          path: ':tourId',//canActivate: [BikemainguardGuard],
          loadChildren: () => import('./tab1/tourismus/bike-main/tour-detail/tour-detail.module').then( m => m.TourDetailPageModule)
        // loadChildren: () => import('./tab1/tourismus/bike-main/bike-main.module').then( m => m.BikeMainPageModule)
        },*/
        
       
      ]
    },  {
      path: 'bike-main/:biketypeId',//canActivate: [BikemainguardGuard], //E-Bike, Rennrad ...
      loadChildren: () => import('./tab1/tourismus/bike-main/biketypes/biketypes.module').then( m => m.BiketypesPageModule)
     //loadChildren: () => import('./tab1/tourismus/bike-main/bike-main.module').then( m => m.BikeMainPageModule)
    },
    {
      path:'wandern',
      children:
      [
        {
          path: '',
          loadChildren: () => import('./tab1/tourismus/wandern/wandern.module').then( m => m.WandernPageModule)
        },
        {
          path: 'slowtrails',
          loadChildren: () => import('./tab1/tourismus/wandern/slowtrails/slowtrails.module').then( m => m.SlowtrailsPageModule)
        },
        {
          path: 'wanderwegespittal',
          loadChildren: () => import('./tab1/tourismus/wandern/wanderwegespittal/wanderwegespittal.module').then( m => m.WanderwegespittalPageModule)
        },
        {
          path: 'pilgern',
          children: 
          [
            {
              path: '',
              loadChildren: () => import('./tab1/tourismus/wandern/pilgern/pilgern.module').then( m => m.PilgernPageModule)
            },
            {
              path: ':pilgerwegId',
              loadChildren: () => import('./tab1/tourismus/wandern/pilgern-detail/pilgern-detail.module').then( m => m.PilgernDetailPageModule)
            },
          ]
        },
        {
          path: 'almhuetten',
          children:
          [
            {
              path: '',
              loadChildren: () => import('./tab1/tourismus/wandern/almhuetten/almhuetten.module').then( m => m.AlmhuettenPageModule)
            },
          ]
        },
        {
          path: 'wanderwege',
          children:
          [
            {
              path: '',
              loadChildren: () => import('./tab1/tourismus/wandern/wanderwege/wanderwege.module').then( m => m.WanderwegePageModule)
            },
            {
              path: 'alpenadriatrail',
              loadChildren: () => import('./tab1/tourismus/wandern/wanderwege/alpenadriatrail/alpenadriatrail.module').then( m => m.AlpenadriatrailPageModule)
            },
            {
              path: 'viaparadiso',
              loadChildren: () => import('./tab1/tourismus/wandern/wanderwege/viaparadiso/viaparadiso.module').then( m => m.ViaparadisoPageModule)
            },
            {
              path: 'nockbergetrail',
              loadChildren: () => import('./tab1/tourismus/wandern/wanderwege/nockbergetrail/nockbergetrail.module').then( m => m.NockbergetrailPageModule)
            },
          ]
        },
      ]
},
{
  path: 'nockmobil',
  loadChildren: () => import('./tab1/tourismus/nockmobil/nockmobil.module').then( m => m.NockmobilPageModule)
},
{
  path: ':tourId',//canActivate: [BikemainguardGuard],
  loadChildren: () => import('./tab1/tourismus/bike-main/tour-detail/tour-detail.module').then( m => m.TourDetailPageModule)
// loadChildren: () => import('./tab1/tourismus/bike-main/bike-main.module').then( m => m.BikeMainPageModule)
},

 ] 
},

  //Pfad zur Restaurantübersicht
  {
    path:'restaurants',
    children:[
      {
        //Standard Pfad zur Restaurantübersicht
        path: '',
        loadChildren: () => import('./tab1/restaurants/restaurants.module').
        then( m => m.RestaurantsPageModule)
      },
      {
        //Pfad zur Detailseite des jeweiligen Restaurants
        path: ':restaurantId',
        loadChildren: () => import('./tab1/restaurants/restaurants-detail/restaurants-detail.module')
        .then( m => m.RestaurantsDetailPageModule)
      }
    ]
  },
  {
    path: 'unterkuenfte',
    children:[
    {
      path: '',
      loadChildren: () => import('./tab1/unterkuenfte/unterkuenfte.module').then( m => m.UnterkuenftePageModule)
    },
    ]
  },
  

  //Pfad zu den Audioguideoptionen
  //Pfad zur freien Auswahl
  {
    path: 'frei',
    loadChildren: () => import('./tab3/frei/frei.module').then(m => m.FreiPageModule)
  },

  //Pfad zur Startseite der geführten Tour (MAP)
  {
    path: 'gefuehrt-start',
    loadChildren: () => import('./tab3/gefuehrt-start/gefuehrt-start.module').then(m => m.GefuehrtStartPageModule)
  },
  
  //Pfad zu den Detailseiten der geführte Tour
  {
    path: 'gefuehrt',
    children: [
    {
      //Standardpfad
      path: '',
      loadChildren: () => import('./tab3/gefuehrt/gefuehrt.module').then(m => m.GefuehrtPageModule)
    },
    {
      //Pfad zur Detailseite der jeweiligen Audioguide-Station
      path: ':stationId',
      loadChildren: () => import('./tab3/gefuehrt/gefuehrt.module').then( m => m.GefuehrtPageModule)
    }
    ]
  },

  //Hinzufügen von Children-Elementen des Hauptpfades
  {
  path: '',
  children: [
    {
      //Einbinden des Tabs-Modules
      path:'',
      loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
    },
    {
      //Einbinden der NewsCard-Elemnte (aktuelle News in Spittal)
      path: ':newsCardId',
      loadChildren: () => import('./tab1/news-detail/news-detail.module').then( m => m.NewsDetailPageModule)
    }
  ]     
  },   {
    path: 'tab4',
    loadChildren: () => import('./tab4/tab4.module').then( m => m.Tab4PageModule)
  },
  {
    path: 'secret',
    loadChildren: () => import('./secret/secret.module').then( m => m.SecretPageModule)
  },

 
  
 
  
  
  
  
  
  
 
 
  
  
]; //Ende des Routing Arrays

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//imports für Translation
import { HttpClientModule, HttpClient }from '@angular/common/http';
import { TranslateModule, TranslateLoader }from '@ngx-translate/core';
import { TranslateHttpLoader }from '@ngx-translate/http-loader';

import { IonicStorageModule } from '@ionic/storage-angular';
import { Storage } from '@ionic/storage-angular';
import { FormsModule } from '@angular/forms';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Network } from '@ionic-native/network/ngx';
import {Media} from '@ionic-native/media/ngx';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
            HttpClientModule,
            IonicStorageModule.forRoot(), 
            TranslateModule.forRoot({
              loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
              }
            }),
            FormsModule
            ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, AndroidPermissions, Network, Media],            
  bootstrap: [AppComponent],
})

export class AppModule {

  constructor(private storage: Storage) {
    this.storage.create();
    this.storage.set('checkView', true);
  }
}

import { Component } from '@angular/core';
import {
  AlertController,
  Platform,
  RangeCustomEvent,
  ToggleCustomEvent,
  ViewDidEnter,
} from '@ionic/angular';
import { RangeValue } from '@ionic/core';
import { SettingsService } from './services/settings.service';
import { Preferences } from '@capacitor/preferences';
import * as L from 'leaflet';
import 'leaflet.awesome-markers';
import { Network } from '@ionic-native/network/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  //Variable zum Speichern der lokalen Schriftgröße
  fontSize: number;

  constructor(
    private network: Network,
    private platform: Platform,
    private settingsService: SettingsService,
    private alertController: AlertController
  ) {
    this.initializeApp();

    L.AwesomeMarkers.Icon.prototype.options.prefix = 'fa';

    settingsService.selectedFontsize.subscribe((x) => {
      this.fontSize = x;
    });

    settingsService.selectedTheme.subscribe((x) => {
      this.darkTheme = x == 'dark';
    });
  }

  //Schriftgröße ändern
  //Variablen zum Speichern der RangeWerte
  rangeAktuellerWert: RangeValue;
  darkTheme: boolean;

  //Funktion zur Bestimmung des aktuellen Wertes (Ausgabe für den Nutzer)

  onIonChange(ev: Event) {
    this.rangeAktuellerWert = (ev as RangeCustomEvent).detail.value;

    //Änderung der Schriftgröße des Testtextes
    const text = document.querySelector<HTMLElement>('.demo');
    text.style.fontSize = (ev as RangeCustomEvent).detail.value + 'px';

    //Änderung der Schriftgöße im FontSizeService
    const aktuellerWertString = (
      ev as RangeCustomEvent
    ).detail.value.toString();
    const aktuellerWertInt = parseInt(aktuellerWertString);
    this.fontSize = aktuellerWertInt;
    this.settingsService.updateFontsize(this.fontSize);
  }

  async checkConnection() {
    const alert = await this.alertController.create({
      header: 'Keine Internetverbindung',
      message:
        'Die App erfordert eine Internetverbindung. Bitte stellen Sie eine Verbindung her und versuchen Sie es erneut.',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            console.log('CLOSE APP');

            navigator['app'].exitApp();
          },
        },
      ],
      backdropDismiss: false,
    });

    if (this.network.type == this.network.Connection.NONE) {
      await alert.present();
    }

    this.network.onDisconnect().subscribe(async x => {
      await alert.present();
    })
  }

  //Dark Mode
  aendereTheme(event) {
    this.settingsService.updateTheme(event.detail.checked ? 'dark' : 'light');
  }

  //Sprachservice (Default Language setzen)
  initializeApp() {
    this.checkConnection();

    this.platform.ready().then(() => {
      this.settingsService.setInitialAppLanguage();
    });
  }

  //Sprache ändern
  async changeLanguage(event) {
    this.settingsService.setLanguage(event.detail.value);
    await Preferences.set({
      key: 'selectedLanguage',
      value: event.detail.value,
    });
  }

  getLanguage() {
    let selected = this.settingsService.selectedLanguage;
    let language;

    if (selected == 'de') {
      language = 'Deutsch';
    } else if (selected == 'en') {
      language = 'English';
    } else if (selected == 'it') {
      language = 'Italiano';
    } else {
      language = 'select Language';
    }

    return language;
  }

  //Funktion um die Permission für Pushbenachrichtigungen zu setzen/ zu löschen
  setpermission: Boolean;

  permission(ev: Event) {
    this.setpermission = (ev as ToggleCustomEvent).detail.checked;

    if (this.setpermission == true) {
      console.log('gesetzt');
    } else {
      console.log('nicht gesetzt');
    }
  }
} //End class AppComponents
